import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.css';

import { Row, Col, Container } from 'react-bootstrap';
import Logo from './Logo';
import Cart from './Cart';
import Search from './Search';
import Location from './Location';
import CategoryMenu from '../category/CategoryMenu';
import { toggleMenuState } from '../../actions/CategoryAction';
//import {addStyle} from '../../utils/CommonUtil';


const Header = props => {

    /* const [stylePath, setStylePath] = useState('./assets/css/style1.css');
    
    const [toggle, setToggle] = useState(false);
    const handleButtonClick = (e) => {  
      setToggle(!toggle);
      setStylePath((toggle) ? './assets/css/style1.css' : './assets/css/style2.css');
      console.log('handleButtonClick theme changed to ', stylePath);
    }

    useEffect(() => {
      addStyle(stylePath);
    }, [stylePath]);  */


    const renderPhone = () => {
        return props.phone ? props.phone.custservice : null
        /* let mobileNum = null;
        if(props.phone){
        let array = props.phone.custservice.split('/');
        array[0] = array[0].trim();
        array[1] = array[1].trim();

        if(props.mobileState){
            mobileNum = (
                <>
                {array[0]}
                <br/>
                <span style={{marginLeft:"19px"}}>
                {array[1]}
                </span>
                </>
            );
        }else{
            mobileNum = `${array[0]} / ${array[1]}`;
        }
    }
        return props.phone ?  mobileNum: null */
    }

    return (
        <React.Fragment>
            <div className="container p-0-mob">
                <Row className="topsection-header">
                    <Col md={2} xs={3} className="text-center mb-10-mob"><Logo /></Col>
                    <Col md={2} xs={8} className="pl-0-mob p-0 font-12 mb-10-mob colorfff"><i className="fa fa-phone-square fs-14" aria-hidden="true"></i>&nbsp;&nbsp;<b className="no-mobfont">{renderPhone()}</b></Col>
                    <Col md={12} xs={4} className="mob-category pr-10-mob mb-10-mob">
                        <button onClick={props.toggleMenuState} className="mob-categorybutton w-100 font-size14px">Category&nbsp;&nbsp;<i className="fa fa-chevron-down d-inline font12px"></i></button>
                    </Col>
                   
                    <Col md={5} xs={8} className=" pl-0-mob mb-10-mob"><Search /></Col>
                   
                    <Col md={1} xs={3} className="p-0-tab p-0 text-center mob-location-custom mb-10-mob"><Location /></Col>
                    <Col md={2} xs={2} className="text-center pl-0-tab p-0 mb-10-mob"><Cart /></Col>
                    {/* <Col>
                        {true && 
                        <button type="button" onClick={handleButtonClick}>
                            theme
                        </button>
                        }
                    </Col> */}
                </Row>
                
                <Row className="topsection-category p-0-xs p-0-tab">
                    <Col className="p-0-xs p-0-tab">
                        <Container className="p-0-xs">
                            <Row>
                                <Col className="p-0-mob"><CategoryMenu /></Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </div>
        </React.Fragment> 
    )
}

const mapStateToProps = state => ({
    phone: state.HomeData.phoneno,
    mobileState:state.CategoryData.isMobileMenu
})

export default connect(mapStateToProps, {toggleMenuState}) (Header);