import { apiAction } from '../middleware/api/apiActions';
import API from './APIConst';

// Description on const CATEGORYLIST

const PRODUCT_LIST = "PRODUCT_LIST";
const SELECTED_PRODUCT = "SELECTED_PRODUCT";
const FILTERED_PRODUCTS_BY_CATEGORY = "FILTERED_PRODUCTS_BY_CATEGORY";
const VARIANT_MODAL = "VARIANT_MODAL";
const VARIANT_LIST = "VARIANT_LIST";
const CLEAR_VARIANTS = "CLEAR_VARIANTS";

const FEATURED_ITEMS = "FEATURED_ITEMS";
//.....................................
/**
 * Action getProductList defined.
 * @constructor
 */
export const getProductsList = (cid) => {
    return apiAction({
        url: `${API.GET_ALL_ITEMS}${cid}`,
        onSuccess: setProductsList,
        onFailure: () => console.log("Error occured in getCategoryList"),
        label: PRODUCT_LIST
    });
}

export const setProductsList = param => ({
    type: PRODUCT_LIST,
    payload: param.data
});

/**
 * Action getfeaturedItems defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */ 
export const getfeaturedItems = (cid) => {
    return apiAction({
        url: `${API.GET_FEATURE_ITEMS}${cid}`, 
        onSuccess: setfeaturedItems,
        onFailure: () => console.log("Error occured in getCompanyInfo"),
        label: FEATURED_ITEMS
      });
}

export const setfeaturedItems = param => ({
    type: FEATURED_ITEMS,
    payload: param.data
});  

//.....................................
/**
 * Action getItemsByVariant defined.
 * @constructor
 */
export const getItemsByVariant = (obj) => {
    return apiAction({
        url: `${API.GET_ITEMS_BY_VARIANT}`,
        method: "post",
        data: obj,
        onSuccess: setItemByVariant,
        onFailure: () => console.log("Error occured in getItemsByVariant"),
        label: VARIANT_LIST
    });
}

export const setItemByVariant = param => {
    return ({
        type: VARIANT_LIST,
        payload: param.data
    })
};
//...................................................................
/**
 * Action  defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const filterByCategory = param => ({
    type: FILTERED_PRODUCTS_BY_CATEGORY,
    payload: param
});

//...................................................................
/**
 * Action  defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const selectProduct = param => ({
    type: SELECTED_PRODUCT,
    payload: param
});


//...................................................................
/**
 * Action setVariantModal defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const setVariantModal = param => ({
    type: VARIANT_MODAL,
    payload: param
});

//...................................................................
/**
 * Action setVariantModal defined.
 * @constructor
 * 
 */
export const clearVariants = () => ({
    type: CLEAR_VARIANTS
});
//...................................................................
/**
 * set up with initial state.
 */
const initialState = {
    productsList: [],
    featuredItems:[],
    selectedProduct: {},
    variantModal: false,
    variantList:[],
    filteredProductsByCategory: "",
	}

/**
 * reducer .
 * @constructor
 * @param {string} state - state of the data shape.
 * @param {string} action - action payload passed for data updates.
 */
let reducer = (state = initialState, action) => {
    switch (action.type) {
        case PRODUCT_LIST: {
                return {
                    ...state,
                    productsList: action.payload
                }
            }
        case VARIANT_LIST: {
            return {
                ...state,
                variantList: action.payload
            }
        }
        case SELECTED_PRODUCT: {
            return {
                ...state,
                selectedProduct: action.payload
            }
        }
         case CLEAR_VARIANTS: {
            return {
                ...state,
                variantList: []
            }
        }
        case VARIANT_MODAL: {
            return {
                ...state,
                variantModal: action.payload
            }
        }

        case FEATURED_ITEMS: return {...state, featuredItems: action.payload }

        default:
            return state;
    }
}

//...................................................................
//.. selectors :: TODO


//...................................................................
/**
 * Export Object (Action + Reducer)
 */
const ProductsAction = {
    reducer: reducer,
    actions: {
        getProductsList,
        filterByCategory,
        selectProduct,
        getItemsByVariant,
        setVariantModal,
        clearVariants
    }
}

export default ProductsAction