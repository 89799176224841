import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';

import ThemeSwitcher from 'react-css-vars';

import Header from '../components/header/Header';
import Footer from '../components/Footer';
import Brands from '../components/Brands';

import { getProductsList} from '../actions/ProductsAction';
import { setCheckOutCartState } from '../actions/CartAction';

const HomeContainer = props => {

  const {companyid, brands, setCheckOutCartState, getProductsList } = props;
  //.. initial call for company and app setup

  useEffect(() => {
      setCheckOutCartState(false);
  }, []);
  
  useEffect(() => {
    if(companyid && companyid !== '') {
      getProductsList(companyid);
    }
  }, [companyid]);
    
    return (
      <React.Fragment>
        <header>
         <Header />
        </header>
        <div className="full-Pagesection">
          {brands && <Brands />}        
          <Outlet />
        </div>
        <Footer />
      </React.Fragment>
    );
  
}

const mapStateToProps = state => ({
  companyid:state.HomeData.companyid,
  brands:state.HomeData.brands,
  productsList: state.ProductsData.productsList
})

export default connect(mapStateToProps, { getProductsList, setCheckOutCartState })(HomeContainer);