import React, { useState,useEffect } from "react";
import ImageUtil from '../../utils/ImageUtil';
import Nav from 'react-bootstrap/Nav';
import { connect } from "react-redux";
import {NavDropdown} from 'react-bootstrap';
import { Global } from '../../utils/Env';

const Codesep = (props) => {

	const test = () =>
	{
		let content=[];
		for(var i=0;i<props.count;i++)
		content=[content,(<div key={i}><br /> </div>)];
		return content;
	}
	return (
		<React.Fragment>
	   <div >{test()}</div>
	   </React.Fragment>
	);
	
}


const Category = props => {
	
	const in_category=(obj) =>
	{
		let test=false;
		if( obj !== null && typeof obj == "object" ) {
			Object.entries(obj).forEach(([key, value]) => {
				if((value instanceof Array) && value.length>0)
					test=value;
			});
		}
		return (test)?test:false;
	}
	
	const parse=(obj) => 
	{
		let final_data=[];
		const parseit=(obj1,level) =>
		{
			if(obj1 instanceof Array)
			{
				for(var i=0;i<obj1.length;i++)
				{
					if(in_category(obj1[i]))
					{
						final_data.push({"name":obj1[i].name,"count":0,"level":level});
						parseit(in_category(obj1[i]),level+1);
					}
				}
			}
			else
			{
				if(in_category(obj1))
				{
					final_data.push({"name":obj1.name,"count":0,"level":level});
					parseit(in_category(obj1),level+1);
				}
			}
		}
		parseit(obj,0);
		return final_data;
	}

 const [extra_lines,setextraLines]=useState(0);
 const [state_values, setstateValues] =useState(parse(props.category));
  
  
useEffect(() => {
		setextraLines(0);
		setstateValues(parse(props.category));
}, [props.menuState]);

  const getIcon = icon => {
    return (
      <label className="mb-0">
        <img className="icon-image hidden-xs hidden-sm" alt={icon} src={ImageUtil.getImage(icon)} />&nbsp;&nbsp;
        <br className="hidden-xs"/>
      </label>
    ) 
  }
  
  const in_category_name=(obj) =>
 {
	let test=false;
	if( obj !== null && typeof obj == "object" ) {
        Object.entries(obj).forEach(([key, value]) => {
            if((value instanceof Array)  && value.length>0)
				test=key;
		});
	}
	 return (test)?test:false;
 } 
 
	
const selected=() => {
	setextraLines(0);
	setstateValues(parse(props.category));
}

 const traverse_sub =(obj,parent1) =>
  {
	  let content=[];
	  let obj1=in_category(obj);
	  let name=in_category_name(obj);
	  for(var i=0;i<obj1.length;i++)
			{
			let category=obj1[i];
			if(in_category(category))
				content=[content,(traverse_categories(category,parent1.concat(' '),name))];
			else
				content=[content,(<NavDropdown.Item key={parent1.concat(' ').concat(name).concat(':').concat(category.id)} eventKey={parent1.concat(' ').concat(name).concat(':').concat(category.id)} onSelect={selected} > {category.name} </NavDropdown.Item>)];
			}
	return (
		<React.Fragment>
        { content }
		</React.Fragment>
    );	
  }

  const clicked=(e,a,count) => {
	  let temp=JSON.parse(JSON.stringify(state_values));
	  for(var i=0;i<temp.length;i++)
	  {
		  if(temp[i].name==a)
		  {
			  if(temp[i].count!=0)
			  {
				  temp[i].count=0;
				  for(var j=i+1;j<temp.length;j++)
				  {
					  if(temp[j].level>temp[i].level)
					  {
						  temp[j].count=0;
					  }
					  else
						  break;
				  }  
				  let sum=0;
				  for(j=0;j<temp.length;j++)
					  if(temp[j].level<=1)
					  sum=sum+temp[j].count;
				  setextraLines(sum);
			  }
			  else
			  {
				  temp[i].count=count;
				  for(var j=0;j<temp.length;j++)
				  {
					  if((temp[j].level==temp[i].level) && (temp[j].name!=temp[i].name))
					  {
						  temp[j].count=0;
						  for(var k=j+1;k<temp.length;k++)
						{
							if(temp[k].level>temp[j].level )
							{
								temp[k].count=0;
							}
							else
							break;
						}
					  }
				  }
				  let sum=0;
				  for(j=0;j<temp.length;j++)
					  if(temp[j].level<=1)
					  sum=sum+temp[j].count;
				  if(temp[i].level>=1)
				  setextraLines(sum-1);
				else
					setextraLines(sum);
				  
			  }
			  break;
		  }
			  
		}
		e.stopPropagation();
		console.log("temp values",temp);
		setstateValues(temp);
	}
	 

   const traverse_categories = (obj,parent1,name) => 
  {
	  let content=[];
	  let name1=(name==' ')?("category"):(name);
	  let title=<React.Fragment>{obj.icon && getIcon(obj.icon)}{obj.name}</React.Fragment>;
	  if(in_category(obj))
	  {
		  if(name==' ')
	  		content=[content,(<NavDropdown key={obj.name}  className={"subcategory_dropdown ".concat("level ").concat(in_category_name(obj))} drop={(name==' ' || props.isMobileMenu)?"down":"right"} onClick={props.isMobileMenu?(e) =>{clicked(e,obj.name,in_category(obj).length)}:null} onSelect={selected} onToggle={selected} title={title} id="nav-dropdown"> {traverse_sub(obj,parent1.concat(name1).concat(":").concat(obj.id))}</NavDropdown>)];
		  else
		  content=[content,(<React.Fragment key="test"><NavDropdown key={parent1} className={"subcategory_dropdown ".concat("level ").concat(in_category_name(obj))} drop={(props.isMobileMenu)?"down":"right"} onClick={props.isMobileMenu?(e) =>{clicked(e,obj.name,in_category(obj).length)}:null} title={title} onSelect={selected}  id="nav-dropdown"> {traverse_sub(obj,parent1.concat(name1).concat(":").concat(obj.id))}</NavDropdown> <div key={name}><Codesep key={name} count={(state_values.filter((item) => item.name === obj.name)[0].count-1)*2-1}/></div></React.Fragment>)];
		}
		else
			content=[content,(<Nav.Link key={parent1.concat(name1).concat(":").concat(obj.id)} className="category_root" eventKey={parent1.concat(name1).concat(":").concat(obj.id)}  >{obj.icon && getIcon(obj.icon)}{obj.name} </Nav.Link>)];
	return (
        <div key={"traverse ".concat(props.category.name)} >
        { content }
      </div>
    );
  }

  return (
	  <React.Fragment>
    <Nav.Item key={props.keyValue} >
		{traverse_categories(props.category,'',' ')}
    </Nav.Item> 
	<Codesep count={(typeof state_values!="undefined" && state_values.length-1>2)?(extra_lines-(state_values.length-2))*2:(extra_lines)*2}/>
	</React.Fragment>
  );
};
const mapStateToProps = state => ({
  selectedCategory: state.CategoryData.selectedCategory,
  selectedsubsubCategory: state.CategoryData.selectedsubsubCategory,
  isMobileMenu: state.CategoryData.isMobileMenu,
  menuState: state.CategoryData.menuState
})
export default connect(mapStateToProps)(Category);